import * as React from 'react';
import { graphql } from 'gatsby';
import ContextConsumer from '../../helpers/context';

import Template from '../../templates/policy';

const PolicyPage = ({ data }) => {
  
  const policy = data.med.pagePolicy;

  return (
    <ContextConsumer>
      {({ data, set }) => (
        <Template
          contextData={data}
          contextSet={set}
          langTo={'/polityka-prywatnosci'}
          data={policy}
        />
      )}
    </ContextConsumer>
  );
};

export const pagePolicyQuery = graphql`
  {
    med {
      pagePolicy(where: { id: "ckylfxauwn3lz0b542avzh4vz" }, locales: en) {
        content {
          markdown
          html
          raw
        }
        seoDescription
        seoImage {
          url
        }
        seoTitle
      }
    }
  }
`;

export default PolicyPage;
